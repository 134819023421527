<template>
  <div>
    <div class="all_container">
      <div class="container">
        <!-- 首页切换 -->
        <div class="changeClassify">
          <div class="title" @click="gohome">{{ $t("other.homePage") }}</div>
          <div>-</div>
          <div class="title">{{ $t("broker") }}</div>
          <div>-</div>
          <div>{{ $t("brokerParticulars") }}</div>
        </div>

        <div class="main_body">
          <div class="left">
            <div class="order">
              <div class="img">
                <img
                  src="@/assets/particulars_icons/left.png"
                  alt=""
                  class="icon"
                />
                <img :src="company.head_img" alt="" class="photo" />
                <img
                  src="@/assets/particulars_icons/right.png"
                  alt=""
                  class="icon"
                />
              </div>
              <div class="name">
                {{ company.name }}
              </div>
            </div>
            <div class="company">
              <div class="name">
                <div class="company_name">
                  {{ company.company }}
                </div>
                <div>
                  <img src="@/assets/particulars_icons/111.png" alt="" />
                  {{ $t("businessHours") }}：{{ company.work_time }}
                </div>
                <div>
                  <img src="@/assets/particulars_icons/222.png" alt="" />
                  {{ $t("agentType") }}：
                  {{ lang == "zh" ? company.type_name : company.sp_name }}
                </div>
                <div>
                  <img src="@/assets/particulars_icons/333.png" alt="" />
                  {{ company.address }}
                </div>

                <div class="btn" @click="dialogVisible = true">
                  {{ $t("contactCompany") }}
                </div>
              </div>
              <div class="content">
                <div class="title">
                  {{ $t("aboutCompany") }}
                </div>

                <div class="intro">
                  {{ company.content }}
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div
              class="item"
              v-for="(item, index) in borkerList"
              :key="index"
              @click="goParticulars(item)"
            >
              <div class="item_top">
                <img :src="item.thumb[0]" alt="" />
              </div>
              <div class="item_bottom">
                <div class="title">{{ item.title }}</div>
                <div class="money">${{ item.price }}</div>
                <div class="address">
                  <div class="address_box">
                    <img
                      src="@/assets/new_home_icons/address_icon.png"
                      alt=""
                    />
                    {{ item.address }}
                  </div>
                  <div class="address_box">
                    <img src="@/assets/particulars_icons/photo.png" alt="" />
                    1/{{ item.thumb.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <div class="pagination">
          <div class="total">
            {{ $t("home.gong") }} {{ total }} {{ $t("home.tiao") }}
          </div>
          <div>
            <el-pagination
              :background="true"
              layout="prev, pager, next"
              :total="total"
              @prev-click="prev"
              @next-click="next"
              :page-size="pageSize"
              @current-change="handleCurrentChange"
              :current-page.sync="pageNo"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="" :visible.sync="dialogVisible">
      <div slot="title">
        <div class="title_all">
          <!-- <div class="title_img"><img src="@/assets/login/flag.png"></div> -->
          <div>{{ $t("contactCompany") }}</div>
        </div>
      </div>
      <div class="dialog_box">
        <div
          v-for="(item, index) in contactList"
          :key="index"
          class="dialog_box_item"
        >
          <img :src="item.img" alt="" />
          <span
            >{{ lang == "zh" ? item.name : item.bnm_name }} :
            {{ item.num }}</span
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{
          $t("Know")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "", // 父页面传来的id
      classifyStatus: "",
      recommendList: [], //热门推荐列表
      likeList: [], //喜欢列表

      total: 8, //总条数
      currentPage: 1, //当前页数
      pageSize: 16, //每页的条数
      pageSizes: [16],

      classify: [],

      lang: "",
      vip: "",
      loadingValue: false,
      tap: false,
      dialogVisible: false,
      contactList: [
        {
          name: "Web",
          bnm_name: "Web",
          img: require("@/assets/particulars_icons/order_icons/1.png"),
          num: "1123456",
        },
        {
          name: "Skype",
          bnm_name: "Skype",
          img: require("@/assets/particulars_icons/order_icons/2.png"),
          num: "1123456",
        },
        {
          name: "Facebook",
          bnm_name: "Facebook",
          img: require("@/assets/particulars_icons/order_icons/3.png"),
          num: "1123456",
        },
        {
          name: "Instagram",
          bnm_name: "Instagram",
          img: require("@/assets/particulars_icons/order_icons/4.png"),
          num: "1123456",
        },
        {
          name: "Twitter",
          bnm_name: "Twitter",
          img: require("@/assets/particulars_icons/order_icons/5.png"),
          num: "1123456",
        },
        {
          name: "Linkedin",
          bnm_name: "Linkedin",
          img: require("@/assets/particulars_icons/order_icons/6.png"),
          num: "1123456",
        },
        {
          name: "WhatsApp",
          bnm_name: "WhatsApp",
          img: require("@/assets/particulars_icons/order_icons/7.png"),
          num: "1123456",
        },
        {
          name: "经纪人执照号",
          bnm_name: "Número de Licencia",
          img: require("@/assets/particulars_icons/order_icons/8.png"),
          num: "1123456",
        },
        {
          name: "手机号",
          bnm_name: "Celular",
          img: require("@/assets/particulars_icons/order_icons/9.png"),
          num: "1123456",
        },
        {
          name: "邮箱",
          bnm_name: "Email",
          img: require("@/assets/particulars_icons/order_icons/10.png"),
          num: "1123456",
        },
        {
          name: "地址",
          bnm_name: "Dirección",
          img: require("@/assets/particulars_icons/order_icons/11.png"),
          num: "1123456",
        },
      ],

      //分页数据
      pageNo: 1,
      pageSize: 10,
      total: 0,

      company: [], //经纪人公司信息
      borkerList: [], //经纪人信息列表
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getBorkerInfo();
    console.log(this.id);
    this.lang = localStorage.getItem("lang");
    console.log(this.$route.params.Classify, "111111");

    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", (e) => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });

    this.getUserInfo();
  },

  mounted() {},
  methods: {
    prev() {
      console.log("上一页");
      this.pageNo--;
      this.getBorkerInfo();
    },
    next() {
      console.log("下一页");
      this.pageNo++;
      this.getBorkerInfo();
    },

    handleCurrentChange(val) {
      this.pageNo = val;
      this.getBorkerInfo();
      console.log(`当前页: ${val}`);
    },

    gohome() {
      this.$router.push({
        path: "",
      });
    },

    // 获取经纪人详情
    getBorkerInfo() {
      this.$http
        .getBorkerInfo({
          user_id: this.id,
          pageNo: this.pageNo,
        })
        .then((res) => {
          if (res.code == 1) {
            console.log(res.data, 5555);
            this.company = res.data.company;
            this.borkerList = res.data.data.data;
            this.total = res.data.data.total;

            this.contactList.forEach((item) => {
              if (item.name == "Web") {
                item.num = this.company.web;
              } else if (item.name == "Skype") {
                item.num = this.company.skype;
              } else if (item.name == "Facebook") {
                item.num = this.company.facebook;
              } else if (item.name == "Instagram") {
                item.num = this.company.instagram;
              } else if (item.name == "Twitter") {
                item.num = this.company.twitter;
              } else if (item.name == "Linkedin") {
                item.num = this.company.linkedin;
              } else if (item.name == "Skype") {
                item.num = this.company.WhatsApp;
              } else if (item.name == "手机号") {
                item.num = this.company.mobile;
              } else if (item.name == "邮箱") {
                item.num = this.company.email;
              } else if (item.name == "地址") {
                item.num = this.company.address;
              }
            });
          }
        });
    },

    goParticulars(item) {
      console.log(item);
      if (item.url == 0) {
        this.$router.push({
          path: "/details",
          query: {
            id: item.id,
            url: item.url,
          },
        });
      } else {
        this.$router.push({
          path: "/particulars",

          query: {
            id: item.id,
            url: item.url,
          },
        });
      }
    },

    // 获取用户信息
    getUserInfo() {
      this.$http.userInfo().then((res) => {
        if (res.code == 1) {
          this.vip = res.data.vip;
        } else if (res.code == -201) {
        } else {
          this.$message.info(res.msg);
        }
      });
    },

    currentChangeClick(currentPage) {
      this.currentPage = currentPage;
    },
    sizeChangeClick(pageSize) {
      this.pageSize = pageSize;
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.active {
  color: #f39800;
}

.no_active {
  color: #333333;
}

.top_fix {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 500;
}

.big_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.all_container {
  background: #f6f7f9;
  // padding-top: 150px;
}

.container {
  width: 100%;
  // height: 446px;
  margin: 0 auto;
  padding-bottom: 64px;
}

.changeClassify {
  display: flex;
  // justify-content: space-between;
  // width: 84px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 32px 0 0 32px;

  .title {
    color: #f39800;
  }
}

.carousel {
  padding: 0 32px;
  margin-top: 36px;
  display: flex;

  .carousel_box {
    display: flex;
    justify-content: left;
    box-sizing: border-box;
    background-color: #fff;
    // height: 258px;
    width: 100%;
    padding: 32px;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    .right {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 40px;

      .top {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        flex-wrap: nowrap;

        .city_name {
          // margin-right: 40px;

          .border {
            width: 40px;
            height: 3px;
            background: rgba(243, 152, 0);
            border-radius: 2px;
            margin-top: 6px;
          }
        }
      }

      .center {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        flex-wrap: nowrap;
        margin: 24px 0px 33px 0px;
      }

      .bottom {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: end;
        box-sizing: border-box;

        .right {
          width: 84px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4177dd;
          white-space: nowrap;
        }
      }
    }
  }
}

.main_body {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0 32px;

  .left {
    width: 440px;

    .order {
      height: 310px;
      background-color: #fff;
      margin-bottom: 32px;
    }

    .img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 48px 54px 16px;

      .icon {
        width: 24px;
        height: 24px;

        &:hover {
          cursor: pointer;
        }
      }

      .photo {
        width: 140px;
        height: 180px;
      }
    }

    .name {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f39800;
    }

    .company {
      .name {
        height: 305px;
        background-color: #fff;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #777777;
        text-align: left;
        padding: 40px 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #e0e0e0;

        div {
          margin-bottom: 16px;
        }

        .company_name {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-bottom: 24px;
        }

        img {
          width: 14px;
          height: 14px;
        }

        .btn {
          width: 376px;
          height: 48px;
          background: #f39800;
          border-radius: 4px;
          line-height: 48px;
          font-size: 16px;
          color: #fff;
          text-align: center;
          margin-top: 40px;
        }
      }

      .content {
        padding: 40px 32px;
        background-color: #fff;

        .title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          text-align: left;
          margin-bottom: 24px;
        }

        .intro {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #777777;
          text-align: left;
          line-height: 24px;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-left: 32px;

    // grid-template-columns: 33% 33% 33%;

    .item {
      width: 32%;
      height: 440px;
      margin-bottom: 60px;

      .item_top {
        width: 100%;
        height: 320px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .item_bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 120px;
        background-color: #fff;
        text-align: left;
        padding: 16px;

        .title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }

        .money {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #e60012;
        }

        .address {
          display: flex;
          justify-content: space-between;

          .address_box {
            display: flex;
            align-items: center;
          }

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}

.pagination {
  display: flex;
  padding: 0 32px;
  margin-top: 46px !important;
  justify-content: space-between !important;
  padding-left: 504px;

  .total {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0);
  }

  :deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
    background-color: #f39800 !important; //修改默认的背景色
  }
}

.hot_recommand {
  box-sizing: border-box;
  display: flex;
  margin-top: 16px;

  .hot_recommand_left {
    width: 232px;

    .two {
      margin-top: 16px;
    }
  }

  .hot_recommand_right {
    width: 968px;
    padding-left: 10px;

    .hot_img {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;

      img {
        width: 205px;
        height: 52px;
      }
    }

    .all_house {
      display: flex;
      flex-wrap: wrap;
    }

    .check_more {
      margin-top: 24px;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 120px;
        height: 36px;
      }
    }
  }
}

.vip {
  display: flex;
  margin-top: 40px;

  .vip_left {
    width: 232px;
  }

  .vip_right {
    width: 968px;

    .vip_img {
      img {
        width: 928px;
        height: 112px;
      }
    }

    .vip_carousel {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;

      .vip_carousel_left {
        width: 474px;
      }

      .vip_carousel_right {
        width: 474px;
      }
    }

    .vip_big_carousel {
      margin-top: 10px;
      width: 958px;
    }
  }
}

.like {
  .like_img {
    margin: 40px 0 20px;

    img {
      width: 316px;
      height: 44px;
    }
  }

  .all_house {
    display: flex;
    flex-wrap: wrap;
  }
}

.carousel_left {
  width: 232px;

  /deep/ .el-carousel__arrow {
    top: 72%;
  }

  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    width: 24px;
    height: 24px;
  }
}

.carousel_left2 {
  width: 232px;

  /deep/ .el-carousel__arrow {
  }

  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    width: 24px;
    height: 24px;
  }
}

/deep/.el-carousel__indicators--horizontal {
  .el-carousel__indicator--horizontal button {
    width: 8px;
    height: 8px;
    background: #ffffff;
    border-radius: 50%;
    opacity: 0.5;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 24px;
    height: 8px;
    background: #ffffff;
    opacity: 0.5;
    border-radius: 10px;
  }
}

.pagination {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

// 新增的样式
.loading {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0rpx;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingImg {
  width: 100rpx;
  height: 100rpx;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 11;
}

/deep/ .el-dialog__body {
  // width: 680px;
  max-height: 445px;
  padding: 16px 32px;

  .dialog_box {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;

    .dialog_box_item {
      display: flex;
      align-items: center;
      justify-content: left;
      padding-bottom: 16px;
      margin-top: 16px;
      border-bottom: 1px solid #dddddd;
    }
    .dialog_box_item:last-child {
      white-space: nowrap;
    }

    .dialog_box_item:last-child {
      border-bottom: none !important;
      padding-bottom: 0 !important;
    }
  }

  // overflow-y: scroll;
  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
}

/deep/.el-dialog__header {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

/deep/.el-dialog__footer {
  text-align: center;
  border-top: 1px solid #dddddd;

  .el-button--primary {
    width: 300px;
    height: 40px;
  }
}
</style>
